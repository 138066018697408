<template>
    <div class="container">
      <div class="list_filter">
        <div class="filter_left">
          <el-input
            v-model="roomName"
            placeholder="直播间名搜索"
            @change="filterList"
            suffix-icon="el-icon-search"
            clearable
          >
          </el-input>
          <el-select v-model="style" clearable placeholder="直播样式" @change="filterList">
            <el-option
              v-for="item in styleOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <!-- <el-select v-model="type" clearable placeholder="直播类别" @change="filterList">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select> -->
          <el-button type="primary" icon="el-icon-plus" @click="openCreateDialog({})">新建直播间</el-button>
        </div>
        <!-- <div class="filter_right">
          <el-button @click="del(checkList)" type="danger" size="small" v-show="edit" :disabled="!checkList.length">批量删除</el-button>
          <el-button @click="isEdit" type="primary" size="small">{{ edit ? '取消编辑' : '编辑'}}</el-button>
        </div> -->
      </div>
      <div class="list_content" v-loading="loading">
        <el-checkbox-group v-model="checkList">
          <ul>
            <li v-for="(item, index) in list" :key="index" @click="!edit && goDetail(item)">
                <div @click="checkBox(item)" class="li_box">
                  <div class="img_box">
                    <img
                      :src="useImg(item.backgroundUrl)"
                      alt=""
                      :style="{
                        height: item.backgroundUrl ? '100%' : '50%'
                      }"
                    >
                  </div>
                  <div class="box_msg">
                    <h3 :title="item.roomName">{{ item.roomName }}</h3>
                    <div class="box_time">
                      <div>
                        <img :src="useIcon(item.roomSizeScale)" alt="">
                      </div>
                      <i class="el-icon-time"></i>
                      <span>{{ showTime(item.createTime) }}</span>
                    </div>
                    <div class="box_operations">
                      <span @click.stop="del(item)">删除</span>
                      <span @click.stop="" @click="openCreateDialog(item)">复制</span>
                    </div>
                  </div>
                </div>
                <el-checkbox :label="item.id" v-show="edit">&nbsp</el-checkbox>
            </li>
          </ul>
        </el-checkbox-group>
        <!-- <div class="pagination" v-show="total > pageSize"> -->
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            :page-sizes="[18, 27, 36, 45]"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <CreateDialog ref="createDialog" @updateList="updateList" />
    </div>
</template>
<script>
import CreateDialog from './createDialog'
import live from '@/assets/images/list/live.svg'
import landscape from '@/assets/images/list/landscape.svg'
import portraitscreen from '@/assets/images/list/portraitscreen.svg'
export default {
  data: function() {
    return {
      edit: false,
      list: [],
      pageSize: 21,
      pageNum: 1,
      total: 0,
      checkList: [], // 选中项
      loading: false,
      style: '', // 样式
      type: '', // 类别
      styleOptions: [
        {
          label: '横屏直播',
          value: '16:9',
        },
        {
          label: '竖屏直播',
          value: '4:3',
        },
      ],
      typeOptions: [
        {
          label: '直播未结束',
          value: 0,
        },
        {
          label: '直播结束',
          value: 3,
        },
      ],
      roomName: '', // 名称|用于关键词筛选
    }
  },
  components: {
    CreateDialog
  },
  mounted() {
    this.getList()
  },
  methods: {
    useImg(value) {
      return value ? `${value.split('|')[0]}?x-oss-process=image/resize,h_100,m_lfit` : live
    },
    useIcon(value) {
      return value === '16:9' ? landscape : portraitscreen
    },
    // 打开批量选中模式
    isEdit() {
      if (this.edit) {
        this.edit = false
        this.checkList = []
      } else {
        this.edit = true
      }
    },
    checkBox(item) {
      // 当是编辑态的时候
      if (this.edit) {
        if (this.checkList.indexOf(item.id) === -1) {
          this.checkList.push(item.id)
        } else {
          this.checkList = this.checkList.filter(value => value !== item.id)
        }
      }
    },
    // 图片删除
    del(item) {
      this.$confirm(`请确定是否${Array.isArray(item) ? '批量' : ''}删除直播间?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        let params
        if (item && item instanceof Object) {
          params = {
            id: item.id,
          }
        }
        if (item && item instanceof Array) {
          params = [...item]
        }
        const data = await this.API.live.delete(params);
        if (data.code == 200) {
          this.$message.success(`${Array.isArray(item) ? '批量' : ''}删除成功`);
          // 当前处于最后一页，并且最后一页只剩下最后一条数据被删除的时候,需要将pageNumber减一
          if (Math.ceil((this.total - 1) / this.pageSize) === this.pageNum || this.pageNum === 1) {
            this.getList()
          } else {
            this.pageNum -= 1
            this.getList()
          }
        } else {
          this.$message.error("删除失败" + JSON.stringify(data));
        }
      });
    },
    // 关键词搜索
    filterList() {
      this.pageNum = 1
      this.getList()
    },
    // 获取直播间列表
    getList() {
      this.loading = true
      const params = {
        roomName: this.roomName || undefined,
        roomSizeScale: this.style || undefined,
        liveStatus: this.type ? [this.type] : this.type === 0 ? [0, 1, 2] : undefined,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      }
      this.API.live.getList(params).then((res) => {
        if (res && res instanceof Object && res.code === 200) {
          const { data } = res
          this.list = data.list
          this.total = data.total
          this.loading = false
        }
      })
    },
    // 去掉筛选条件，回到第一页获取列表数据
    updateList() {
      this.pageNum = 1
      this.type = ''
      this.style = ''
      this.roomName = ''
      this.getList()
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    // pageSize切换
    handleSizeChange(val) {
        this.pageNum = 1;
        this.pageSize = val;
        this.getList();
    },
    // 打开新建直播间弹框
    openCreateDialog(value) {
      this.$refs.createDialog.open(value)
    },
    // 进入直播间使用页
    goDetail(item) {
      // this.$store.commit('liveDetails/setDetail', item)
      localStorage.setItem('room_detail', JSON.stringify(item))
      this.$router.push({
        path: '/formal/detail',
        query: {
          id: item.id,
        },
      })
    },
    showTime(time) {
      if (this.$moment(time).format('YYYY-MM-DD') === this.$moment().format('YYYY-MM-DD')) {
        return `今天 ${this.$moment(time).format('HH:mm')}`
      }
      return this.$moment(time).format('YYYY-MM-DD HH:mm')
    }
  },
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>