<template>
    <el-dialog
        :visible.sync="visible"
        :title="`${roomId !== undefined ? '复制' : '新建'}直播`"
        :close-on-click-modal="false"
        width="714px"
        @close="close"
    >
        <div class="create_content">
            <el-form :model="form" ref="form" label-width="94px">
                <el-form-item label="模式">
                    <div class="pattern">
                        <p>{{ roomId !== undefined ? '复制直播' : '直播创建成功后'}}，模式不能进行修改</p>
                        <el-radio-group v-model="form.roomSizeScale">
                            <div>
                                <el-radio-button label="16:9" :disabled="roomId !== undefined">
                                    <img src="../../../../../assets/images/list/landscape.svg" alt="">
                                </el-radio-button>
                                <h3>横屏项目</h3>
                            </div>
                            <div>
                                <el-radio-button label="4:3" :disabled="roomId !== undefined">
                                    <img src="../../../../../assets/images/list/portraitscreen.svg" alt="">
                                </el-radio-button>
                                <h3>竖屏项目</h3>
                            </div>
                        </el-radio-group>
                    </div>
                </el-form-item>
                <el-form-item
                    prop="roomName"
                    label="直播标题"
                    :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]"
                >
                    <el-input v-model="form.roomName" placeholder="请输入标题名称" maxlength="30" show-word-limit></el-input>
                </el-form-item>
            </el-form>
            
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button @click="submit" type="primary">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { validateForm } from "@/utils/utils";
export default {
    data: function() {
        return {
            visible: false,
            roomId: undefined,
            form: {
                roomName: '',
                roomSizeScale: '16:9',
            },
        }
    },
    methods: {
        open(value) {
            if (value && value instanceof Object && Object.keys(value).length > 0) {
                this.roomId = value.id
                this.form.roomSizeScale = value.roomSizeScale
            }
            this.visible = true
        },
        close() {
            this.$refs.form.resetFields();
            Object.assign(this.$data, this.$options.data())
        },
        async submit() {
            await validateForm(this.$refs.form);
            if (this.roomId !== undefined) {
                this.API.live.copy({
                    roomId: this.roomId,
                    roomName: this.form.roomName,
                    roomSizeScale: this.form.roomSizeScale
                }).then((res) => {
                    if (res && res instanceof Object && res.code === 200) {
                        this.$message.success('复制成功')
                        this.close()
                        this.$emit('updateList')
                    } else {
                        this.$message.error('复制失败')
                    }
                })
            } else {
                this.API.live.create({
                    roomName: this.form.roomName,
                    roomSizeScale: this.form.roomSizeScale
                }).then((res) => {
                    if (res && res instanceof Object && res.code === 200) {
                        this.$message.success('添加成功')
                        this.close()
                        this.$emit('updateList')
                    } else {
                        this.$message.error('添加失败')
                    }
                })
            }
        },
    },
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
    background: rgb(32,32,32);
}
/deep/ .el-dialog__header {
    display: flex;
    justify-Content: center;
    align-items: center;
    .el-dialog__title {
        color: #fff;
        font-size: 18px;
        font-weight: bold;
    }
}
/deep/ .el-dialog__body {
//   padding: 30px 74px;
  padding: 8px 20px 0px;
  .create_content {
        background: #121212;
        padding: 20px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
  }
  .el-form-item {
      margin-bottom: 50px;
  }
  .el-form-item__label {
      color: #fff;
      font-size: 16px;
      text-align: left;
  }
  .el-input__inner {
      background: rgba(0, 0, 0, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.21);
      color: #fff;
  }
  .el-form-item.is-error .el-input__inner {
      border-color: #F56C6C
  }
  .el-input .el-input__count .el-input__count-inner {
      background: unset;
  }
  .pattern {
      > p {
          font-size: 16px;
          color: #FF5050;
          margin-bottom: 8px;
      }
  }
  .el-radio-group {
      display: flex;
      justify-content: space-between;
      width: 16vw;
      > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
           .el-radio-button__inner {
                width: 108px;
                height: 62px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 12px;
                background: rgb(32,32,32);
                color: #fff;
                border-radius: 6px;
                border: 1px solid rgba(255, 255, 255, 0.08);
                > img {
                    height: 100%;
                    width: auto;
                }
            }
            .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                background: rgba(78, 140, 255, 0.2);
                border: 1px solid #4E8CFF;
                box-shadow: unset
            }
          > h3 {
              font-size: 16px;
              color: #fff;
              margin-top: 14px;
          }
      }
  }
}
/deep/ .el-dialog__footer {
    padding: 0px 20px 20px;
    .dialog-footer {
            padding: 0 20px 20px;
            background: #121212;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            .el-button--default {
                border-radius: 4px;
                border: 1px solid #4E8CFF;
                background: #252525;
                color: #4E8CFF;
                margin-right: 60px;
            }
            .el-button--primary {
                border: 1px solid #4E8CFF;
                background: #4E8CFF;
            }
        }
}
</style>